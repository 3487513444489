.pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
@media (min-width: 768px) {
    .pagination-container {
        flex-direction: row;
        justify-content: space-between;
    }
}

.custom-pagination {
    overflow-x: auto; /* Habilitar scroll horizontal */
    white-space: nowrap; /* Evitar saltos de línea */
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centrar elementos */
    gap: 4px; /* Espaciado entre botones */
}
.custom-pagination::-webkit-scrollbar {
    height: 6px; /* Personalizar scrollbar */
}

.custom-pagination::-webkit-scrollbar-thumb {
    background-color: #78be20;
    border-radius: 10px;
}

@media (min-width: 576px) {
    .custom-pagination {
        justify-content: start; /* Alinear a la izquierda en pantallas más grandes */
    }
}
  
.custom-pagination .page-link {
    border-color: #78be20;
    color: #78be20;
}

.custom-pagination .page-link:hover {
    background-color: #66a91b;
    border-color: #66a91b;
    color: white;
}

.custom-pagination .page-item.active .page-link {
    background-color: #78be20;
    border-color: #78be20;
    color: white;
}

.custom-pagination .page-item.disabled .page-link {
    border-color: #d4d4d4;
    color: lightgray
}

.total-records {
    font-size: 12px;
    margin-left: 20px;
}