.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: Sansation;
  src: url(../public/Sansation_Regular.ttf);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #001489;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*MODAL*/
.modal-content{
  border: none;
}

.label-color{
  color: #7c7c7c !important;
}

/*CUSTOM*/

.navbar-title{
  padding-right: 6vh !important;
}

.form-check-input:checked {
  background-color: #5cb200;
  border-color: #5cb200;
}

.toggle-selected {
  background-color: #5cb200 !important;
  border-color: #5cb200 !important;
  color: white !important;
}

.toggle-not-selected {
  background-color: transparent !important;
  border-color: black !important;
  color: black !important;
  opacity: 0.40 !important;
}

input:read-only {
  opacity: 0.65;
}

input[type=file] {
  opacity: 1;
}

.error-title {
  color: gray;
  font-size: 20vh;
}

.form-check-input{
  border-color: #8d949e;
}

.text-gray {
  color: gray;
}

h1,h2,h3,h4,h5{
  color: #021589;
  font-family: 'Sansation', sans-serif !important;
}

td{
  font-size: small
}

.form-control, .recuadro_ab{
  border: 2px solid #78be20;
  background-color: transparent;
}

.reborde {
  border: 2px solid #78be20;
  background-color: transparent;
  border-radius: 0.375rem;
}

.form-select:disabled{
  border: 2px solid #bbbbbb;
  background-color: #bbbbbb;
  color: white;
}

.button{
  border: 0;
  background-color: #78be20;
}

.button-secondary{
  background-color: #8d949e;
}

.button-danger{
  background-color: #c94d4d;
}

.button-block{
  min-width: 100%;
  width: 100%;
}

.button:disabled{
  background-color: #bbbbbb;
  color: white;
}

.button-round {
  border-radius: 50%;
}

input.error {
  border: 1px solid rgb(255, 54, 54);
}
select.error {
  border: 1px solid rgb(255, 54, 54);
}

.input-feedback {
  text-align: start;
  display: block;
  color: rgb(255, 54, 54);
  margin-bottom: .35rem;
  font-size: 11px;
  white-space: pre-wrap;
}

.nav-link {
  color: white !important
}

.hint {
  text-align: start;
  display: block;
  margin-bottom: .35rem;
  font-size: 11px;
  white-space: pre-wrap;
}

.label-custom {
  font-size: .75em;
}

.form-floating > label{
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  color: #7c7c7c;
}

.text-ab {
  color: #79b432;
}

.border-ab {
  border: 2px solid #b8d891;
}

.ab-gradient {
  background: linear-gradient(to right, rgba(92,178,0,1) 0%, rgba(112,186,9,1) 39%, rgba(22,113,119,1) 67%, rgba(22,113,119,1) 100%);
}

.ab-primary {
  background-color: #167177;
  color: white;
}
.ab-primary-elec {
  background-color: rgba(92,178,0,1);
  color: white;
}

.sector-tag {
  border-radius: 15px 15px 0 0
}

.ab-primary-gas {
  background-color: #167177;
  color: white;
}

.main-display-initial {
  display: initial !important;
}

.btn-home {
  font-size: .7vw;
}

@media only screen and (max-width: 600px) {
  .btn-home {
      font-size: 4vw;
  }
}


.card-label-corner {
  border-top-left-radius: 0 !important;
}

.ab-secondary {
  background-color: #79b432;
  color: white;
}

.ab-secondary-outline {
  border: 2px solid #78be20;
  background-color: white;
  color: #78be20;
}

.mt-10 {
  margin-top: 12rem !important;
}

.card-header {
  border-bottom: none;
}

body {
    font-family: 'Myriad Pro', sans-serif;
    color: #999999;
}

.main-content{
  margin-bottom: 15vh;
  height: 75vh;
}

.mb-footer{
  margin-bottom: 15vh;
}

.footer {
  min-width: 100%;
  position: fixed !important;
  bottom: 0px !important;
  left: 0px;
  right: 0px;
  margin-bottom: 0px !important;
  z-index: 100;
}

.link {
  color: white;
  text-decoration: none;
}

/* DatePicker */

.react-datepicker__header {
  background-color: #5cb200 !important;
}

.react-datepicker__current-month, .react-datepicker__day-name{
  color: white !important;
}

.react-datepicker__calendar-icon {
  margin-top: 3px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}


